var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"modal","enter-class":"tw-opacity-0","leave-to-class":"tw-opacity-0","enter-active-class":"tw-transition-opacity tw-duration-300","leave-active-class":"tw-transition-opacity tw-duration-300"}},[(_vm.preloadAndKeepAlive || _vm.value)?_c('div',{staticClass:"tw-fixed tw-inset-0 tw-z-60 tw-transition-all tw-duration-300",class:{
			'modal--is-standard': _vm.isStandard,
			'modal--is-fullscreen': _vm.isFullscreen,
			'modal--is-gallery': _vm.isGallery,
			'modal--is-small': _vm.isSmall,
			'modal--is-dialog': _vm.isDialog,
			'tw-opacity-100 tw-visible': _vm.preloadAndKeepAlive && _vm.value,
			'tw-opacity-0 tw-invisible': _vm.preloadAndKeepAlive && !_vm.value,
		},attrs:{"data-name":"modal","data-id":_vm.uid}},[_c('Overlay',{nativeOn:{"click":function($event){return _vm.handleOverlayClick.apply(null, arguments)}}}),(_vm.preloadAndKeepAlive || _vm.value)?_c('KeyboardAvoidingView',{ref:"keyboardAvoidingView",staticClass:"modal__box-wrap tw-flex tw-justify-center tw-items-end tw-fixed tw-inset-0 tw-pointer-events-none md:tw-justify-end md:tw-items-center",attrs:{"active":_vm.keyboardAvoidingView,"uid":"MODAL_KEYBOARD_AVOIDING_VIEW"}},[_c('div',{staticClass:"modal__box tw-flex tw-flex-col tw-max-w-full tw-max-h-full tw-bg-white tw-rounded-t-2xl tw-pointer-events-auto md:tw-h-full md:tw-rounded-none",class:_vm.BREAKPOINT.IS_MOBILE ? 'tw-w-full' : _vm.widthClass},[(_vm.isHeaderVisible)?_c('div',{staticClass:"modal__box-header tw-px-12 tw-py-5 tw-relative tw-text-center md:tw-px-20 md:tw-text-center",class:!_vm.title ? 'md:tw-py-2 md:tw-mt-7' : 'md:tw-py-5 md:tw-mt-4'},[(_vm.BREAKPOINT.IS_MOBILE || (!_vm.BREAKPOINT.IS_MOBILE && !_vm.showBackArrow))?_c('button',{staticClass:"modal__trigger tw-top-1/2 tw-right-2 tw-transform tw--translate-y-1/2 tw-p-2 tw-absolute tw-text-6 tw-cursor-pointer md:tw-top-1/2 md:tw-right-auto md:tw-left-8",attrs:{"aria-label":_vm.$t(_vm.$i18nKeys.modal.close),"data-name":"modal-close"},on:{"click":_vm.close}},[_c('Ico',{attrs:{"name":"close"}})],1):_vm._e(),(_vm.showBackArrow)?_c('button',{staticClass:"modal__trigger modal__trigger--back tw-top-1/2 tw-left-2 tw-transform tw--translate-y-1/2 tw-p-2 tw-absolute tw-text-6 tw-cursor-pointer md:tw-top-1/2 md:tw-left-8",attrs:{"aria-label":_vm.$t(_vm.$i18nKeys.modal.back),"data-name":"modal-back"},on:{"click":_vm.handleBackClick}},[_c('Ico',{attrs:{"name":"chevron-left"}})],1):_vm._e(),(!_vm.BREAKPOINT.IS_MOBILE && _vm.title && _vm.mode === 'is_small')?_c('SectionTitle',{attrs:{"title":_vm.title}}):(_vm.title)?_c('div',{class:[
							{ 'tw-min-h-5 tw-leading-5': !_vm.isGallery },
							{ 'tw-h-0 tw-min-h-0': _vm.isGallery } ],attrs:{"data-name":"modal-title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),((_vm.title && _vm.mode !== 'is_small') || _vm.BREAKPOINT.IS_MOBILE)?_c('div',{staticClass:"tw-absolute tw-right-0 tw-bottom-0 tw-left-0 tw-border-b tw-border-solid tw-border-snow md:tw-right-6 md:tw-left-6"}):_vm._e()],1):_vm._e(),_c('div',{ref:"modalContent",staticClass:"tw-overflow-auto",class:(_vm.BREAKPOINT.IS_MOBILE && _vm.contentPadding && !_vm.isGallery
							? 'tw-px-6 tw-pt-4 tw-pb-6'
							: '').concat( (!_vm.BREAKPOINT.IS_MOBILE && _vm.contentPadding && !_vm.isSmall && !_vm.isGallery
							? 'tw-px-10 tw-py-6'
							: ''),
						(!_vm.BREAKPOINT.IS_MOBILE && _vm.contentPadding && _vm.isSmall
							? 'tw-px-14 tw-pt-6 tw-pb-14'
							: ''),
						(!_vm.BREAKPOINT.IS_MOBILE && _vm.contentPadding && _vm.isGallery
							? 'tw-pl-5 lg:tw-pl-12'
							: ''),
						(_vm.BREAKPOINT.IS_MOBILE && _vm.contentPadding && _vm.isGallery ? 'tw-p-0 tw-h-full' : '') ),attrs:{"data-name":"modal-content"}},[_vm._t("default")],2),(_vm.$slots.modalFooter)?_c('div',{staticClass:"modal__box-footer tw-py-3 tw-px-6 tw-mt-auto md:tw-py-8",attrs:{"data-name":"modal-footer"}},[_vm._t("modalFooter")],2):_vm._e()])]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }