import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

function lock(unlockedElement?: Element) {
	if (!unlockedElement) return;

	disableBodyScroll(unlockedElement);
}

function unlock() {
	clearAllBodyScrollLocks();
}

export default {
	lock,
	unlock,
};
